<template>
    <div id="app">
        <div class="gjrb">
            <div class="banner">
                <h1>浙江卫视节目的救援</h1>
                <p><span>报告于2020-01-22&nbsp; 09:57由舆情管理系统生成</span></p>
            </div>
            <div class="cont">
                <h2>一、概述</h2>
                <p>本期简报由2019-12-22 11:04:23至2019-12-23 11:04:23在各大网站及社交平台的1242条数据汇整而成。</p>
                <h2>二、监测总览</h2>
                <h3 class="h3">（一）信息焦点</h3>
                <p>本次的1242条信息中，主要的信息焦点为渣台zjws #抵制浙江卫视跨年晚会##高以翔百度百科被篡改# 把死不要脸发挥的淋漓尽致，尽在背后搞些见不得人的小动作，什么时候出来开新闻发布会回应#二十问浙江卫视# @浙江卫视中国蓝，整体信息传播的关键词主要包含高以翔、浙江卫视、追责、百度百科、版本等。上升关键词则包含络腮胡、彬彬有礼、哀悼、发病、承认错误等。</p>
                <table class="xxjd_table1">
                    <tr>
                        <th>序号</th>
                        <th>总体信息焦点</th>
                        <th class="w-163">发布时间</th>
                        <th>来源</th>
                        <th>媒体等级</th>
                        <th>相似数</th>
                        <th>属性</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td><a class="title">渣台zjws #抵制浙江卫视跨年晚会##高以翔百度百科被篡改# 把死不要脸发挥的淋漓尽致，尽在背后搞些见不得人的小动作，什么时候出来开新闻发布会回应#二十问浙江卫视# @浙江卫视中国蓝</a></td>
                        <td class="w-163">2019-12-22 19:31:00</td>
                        <td><a class="source">微博@Godfrey爱你的高高</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><a class="title">给偶像加油 高以翔女友的爸爸晒高以翔的旧照，打脸了高以翔的经纪公司 序言：2019年12月21日，有网友曝光高以翔女友Bella的爸爸社交媒体的最新动态。Bella爸爸晒出一张高以翔回家的背影旧照并配</a></td>
                        <td class="w-163">2019-12-22 14:09:04</td>
                        <td><a class="source">自媒体号 / 微头条</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-mg">敏感</span></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><a class="title">//@我是萧姑娘呀:#高以翔百度百科被篡改# @浙江卫视中国蓝 @紫光阁 @人民日报 @人民网，真的有权有势可以一手遮天吗？为什么到现在都没有给一个正面道歉？还整天买别人热搜骚扰家人，抵制浙江卫视，抵</a></td>
                        <td class="w-163">2019-12-22 18:59:00</td>
                        <td><a class="source">微博@安琪儿-lili</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><a class="title">有空改百度百科没空回应啊？浙江卫视你可真够忙的，忙着跨年拉嘉宾啊？抱歉哦十二月快过去了，我们还没有忘记高以翔 #高以翔百度百科被篡改# #浙江卫视跨年# #抵制浙江卫视跨年晚会# 今早爆出的百度百科词</a></td>
                        <td class="w-163">2019-12-22 17:13:00</td>
                        <td><a class="source">微博@BiBiBiBiBiw</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><a class="title">//@星若_我就是猴子请来的逗比:官博可真牛逼，高以翔的追思会一结束，第二天就假装无视发生，迫不及待地出来营业，现在又篡改百度百科，混淆视听，这么喜欢蹦跶那就回应一下十二问吧 #高以翔百度百科被篡改#</a></td>
                        <td class="w-163">2019-12-22 17:51:00</td>
                        <td><a class="source">微博@_Elaine-易瑜是我</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                </table>
                <table class="xxjd_table2">
                    <tr>
                        <th>热门关键词</th>
                        <th>相关条数</th>
                        <th class="bd-l-20">上升关键词</th>
                        <th>相关条数</th>
                    </tr>
                    <tr>
                        <td><a class="source">高以翔</a></td>
                        <td>963</td>
                        <td class="bd-l-20"><a class="source">络腮胡</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">浙江卫视</a></td>
                        <td>828</td>
                        <td class="bd-l-20"><a class="source">彬彬有礼</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">追责</a></td>
                        <td>819</td>
                        <td class="bd-l-20"><a class="source">哀悼</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">百度百科</a></td>
                        <td>741</td>
                        <td class="bd-l-20"><a class="source">发病</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">声明</a></td>
                        <td>690</td>
                        <td class="bd-l-20"><a class="source">承认错误</a></td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td><a class="source">版本</a></td>
                        <td>315</td>
                        <td class="bd-l-20"><a class="source">急救</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">晚会</a></td>
                        <td>174</td>
                        <td class="bd-l-20"><a class="source">心源性</a></td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td><a class="source">词条</a></td>
                        <td>156</td>
                        <td class="bd-l-20"><a class="source">综艺节目</a></td>
                        <td>24</td>
                    </tr>
                    <tr>
                        <td><a class="source">小动作</a></td>
                        <td>126</td>
                        <td class="bd-l-20"><a class="source">恶意</a></td>
                        <td>20</td>
                    </tr>
                    <tr>
                        <td><a class="source">死不要脸</a></td>
                        <td>126</td>
                        <td class="bd-l-20"><a class="source">别有用心</a></td>
                        <td>7</td>
                    </tr>
                </table>
                <h3 class="mar-t-20 h3">（二）信息声量走势</h3>
                <p>本次的1242条信息中,发布高峰为2019-12-22 17:00:00至2019-12-22 18:00:00的时段,共283条信息,占总量的22.79%.信息高峰的主要焦点为转发微博 #抵制浙江卫视跨年晚会##高以翔百度百科被篡改# 把死不要脸发挥的淋漓尽致，尽在背后搞些见不得人的小动作，什么时候出来开新闻发布会回应#二十问浙江卫视# @浙江卫视中国蓝</p>
                <div class="box-card xxsmzs mar-t-20">
                    <h3 class="mod-title">信息声量走势</h3>
                    <div class="echarts_empty hide">
                        <div class="img"></div>
                    </div>
                    <div class="w-100 h-430" id="voicetrend">
                    </div>
                </div>
                <table class="xxjd_table1">
                    <tr>
                        <th>序号</th>
                        <th>总体信息高峰日焦点</th>
                        <th class="w-163">发布时间</th>
                        <th>来源</th>
                        <th>媒体等级</th>
                        <th>相似数</th>
                        <th>属性</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td><a class="title">转发微博 #抵制浙江卫视跨年晚会##高以翔百度百科被篡改# 把死不要脸发挥的淋漓尽致，尽在背后搞些见不得人的小动作，什么时候出来开新闻发布会回应#二十问浙江卫视# @浙江卫视中国蓝</a></td>
                        <td class="w-163">2019-12-23 07:36:00</td>
                        <td><a class="source">微博@MonaLisa君</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><a class="title">//@我是萧姑娘呀:#高以翔百度百科被篡改# @浙江卫视中国蓝 @紫光阁 @人民日报 @人民网，真的有权有势可以一手遮天吗？为什么到现在都没有给一个正面道歉？还整天买别人热搜骚扰家人，抵制浙江卫视，抵</a></td>
                        <td class="w-163">2019-12-22 17:13:00</td>
                        <td><a class="source">微博@风间琉璃Dr</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><a class="title">有空改百度百科没空回应啊？浙江卫视你可真够忙的，忙着跨年拉嘉宾啊？抱歉哦十二月快过去了，我们还没有忘记高以翔 #高以翔百度百科被篡改# #浙江卫视跨年# #抵制浙江卫视跨年晚会# 今早爆出的百度百科词</a></td>
                        <td class="w-163">2019-12-23 07:36:00</td>
                        <td><a class="source">微博@BiBiBiBiBiw</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td><a class="title">//@JEZTREPUBLIC:#高以翔百度百科被篡改# zjws这么有空改词条怎么不见你出来回应二十问？？？@浙江卫视中国蓝 #高以翔百度百科被篡改# #浙江卫视跨年# #抵制浙江卫视跨年晚会# 今</a></td>
                        <td class="w-163">2019-12-22 17:52:00</td>
                        <td><a class="source">微博@买辣条也用券哇</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td><a class="title">//@星若_我就是猴子请来的逗比:官博可真牛逼，高以翔的追思会一结束，第二天就假装无视发生，迫不及待地出来营业，现在又篡改百度百科，混淆视听，这么喜欢蹦跶那就回应一下十二问吧 #高以翔百度百科被篡改#</a></td>
                        <td class="w-163">2019-12-23 07:36:00</td>
                        <td><a class="source">微博@_Elaine-易瑜是我</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-fmg">非敏感</span></td>
                    </tr>
                </table>
                <h3 class="mar-t-20 h3">（三）敏感信息走势</h3>
                <p>本次的1242条信息，敏感信息共604条，占总量中的48.63%。敏感信息中，需特别关注与高以翔、浙江卫视有关的信息。从走势来看，发布高峰为2019-12-22 18:00:00至2019-12-22 19:00:00的时段，共144条，占所有敏感信息的23.84%，信息高峰的主要焦点为烂台biss#抵制浙江卫视跨年晚会# 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以为大家不知道zjws的所作所为吗有时间找人改百度百科，没时间公开追责进度公开道歉哦。</p>
                <div class="box-card xxsmzs mar-t-20">
                    <h3 class="mod-title">情感声量走势</h3>
                    <div class="echarts_empty hide">
                        <div class="img"></div>
                    </div>
                    <div class="w-100 h-430" id="emotiontrend">
                    </div>
                </div>
                <table class="xxjd_table1">
                    <tr>
                        <th>序号</th>
                        <th>敏感信息高峰日焦点</th>
                        <th class="w-163">发布时间</th>
                        <th>来源</th>
                        <th>媒体等级</th>
                        <th>相似数</th>
                        <th>属性</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td><a class="title">烂台biss#抵制浙江卫视跨年晚会# 高以翔超话#高以翔百度百科被篡改##抵制浙江卫视跨年晚会#看这个编辑记录大家都明白了，zjws真的改了就以为大家不知道zjws的所作所为吗有时间找人改百度百科，没</a></td>
                        <td class="w-163">2019-12-22 18:50:00</td>
                        <td><a class="source">微博@抹灿少女</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-mg">敏感</span></td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td><a class="title">//@平平淡淡Anna:官博可真牛逼，高以翔的追思会一结束，第二天就假装无视发生，迫不及待地出来营业，现在又篡改百度百科，混淆视听，这么喜欢蹦跶那就回应一下二十问吧 #高以翔我们永远不说再见# 篡改百</a></td>
                        <td class="w-163">2019-12-22 18:41:00</td>
                        <td><a class="source">微博@sunyuemxb</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-mg">敏感</span></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td><a class="title">高以翔超话 简直不是人，竟然篡改高先生的百度百科，浙江卫视吃相太难看了这大半个月，高先生已经彻底离开这个世界了。隔三差五的上热搜，网民的坚持，不过是想要高先生得到你们的道歉而已……一而再再而三的出来作</a></td>
                        <td class="w-163">2019-12-22 18:08:00</td>
                        <td><a class="source">微博@求助微博</a></td>
                        <td>普通媒体</td>
                        <td>1</td>
                        <td><span class="text-mg">敏感</span></td>
                    </tr>

                </table>
                <div class="w-100">
                    <div class="box-card w-50 h-500">
                        <h3 class="mod-title">敏感信息占比</h3>
                        <div class="echarts_empty hide">
                            <div class="img"></div>
                        </div>
                        <div class="w-100 h-430" id="sensitive"></div>
                    </div>
                    <table class="xxjd_table2 mgxx">
                        <tr>
                            <th><span class="text-mg">敏感</span> 信息热门关键词</th>
                            <th>相关条数</th>
                        </tr>
                        <tr>
                            <td><a class="source">高以翔</a></td>
                            <td>453</td>
                        </tr>
                        <tr>
                            <td><a class="source">浙江卫视</a></td>
                            <td>379</td>
                        </tr>
                        <tr>
                            <td><a class="source">声明</a></td>
                            <td>370</td>
                        </tr>
                        <tr>
                            <td><a class="source">百科</a></td>
                            <td>338</td>
                        </tr>
                        <tr>
                            <td><a class="source">心源性</a></td>
                            <td>295</td>
                        </tr>
                        <tr>
                            <td><a class="source">追责</a></td>
                            <td>85</td>
                        </tr>
                        <tr>
                            <td><a class="source">词条</a></td>
                            <td>74</td>
                        </tr>
                        <tr>
                            <td><a class="source">晚会</a></td>
                            <td>66</td>
                        </tr>
                        <tr>
                            <td><a class="source">版本</a></td>
                            <td>66</td>
                        </tr>
                        <tr>
                            <td><a class="source">所作所为</a></td>
                            <td>54</td>
                        </tr>
                    </table>
                </div>
                <h3 class="h3">（四）信息分布</h3>
                <p>本次的1242条信息，主要来自于微博的数据，共312条，占了总量的25.12%。从发布渠道来看，微博、评论、网媒发布了最多相关的信息；敏感的信息则主要来自微博、评论、网媒，这些渠道的发布敏感信息的走势呈现大致一致的情况。</p>
                <div class="w-100">
                    <div class="box-card w-50">
                        <h3 class="mod-title">媒体类型占比</h3>
                        <div class="echarts_empty hide">
                            <div class="img"></div>
                        </div>
                        <div class="w-100 h-430" id="mediaSourceMap">
                        </div>
                    </div>
                    <div class="box-card w-50">
                        <h3 class="mod-title">微博主要媒体来源</h3>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a>sunyuemxb</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">5条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a>我当然不好意思在你面前跳</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.28%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">1.28%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a>正在努力中的妹纸</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.28%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">1.28%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a>DGyjn茜茜</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a >Everytime紫爱</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a>哇亲爱的L</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >平平淡淡Anna</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a>槑槑不是四个呆</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">9</span>
                                    <a>独家记忆78015</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">10</span>
                                    <a>秋风6245922800</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.9%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-100">
                    <div class="box-card w-50 h-450">
                        <h3 class="mod-title">评论主要媒体来源</h3>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a>腾讯网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 33.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">42条</span>
                                    <span class="redu">33.6%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a >新浪网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 30.4%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">38条</span>
                                    <span class="redu">30.4%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a >天天快报</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 12%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">15条</span>
                                    <span class="redu">12%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a >趣头条客户端</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 9.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">12条</span>
                                    <span class="redu">9.6%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a >今日头条</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 8%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">10条</span>
                                    <span class="redu">8%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a >企鹅号</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 3.2%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">3.2%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >微头条</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a >搜狐新闻客户端</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="box-card w-50 h-450">
                        <h3 class="mod-title">网媒主要媒体来源</h3>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a >救援抢险网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 33.8%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">23条</span>
                                    <span class="redu">33.82%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a >冲孔机网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 25%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">17条</span>
                                    <span class="redu">25%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a >上海毅兮地坪工程有限公司</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 14.7%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">10条</span>
                                    <span class="redu">14.71%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a >腾讯网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 5.88%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">5.88%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a>澳门豆捞</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 2.94%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">2.94%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a >上游新闻</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >为梦而飞</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a >主机开设成功</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">9</span>
                                    <a >南京南房建设监理咨询有限公司</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">10</span>
                                    <a >南京生活网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-100 sensitive_cont">
                    <div class="box-card w-50">
                        <h3 class="mod-title"><span class="red">敏感</span>信息媒体类型占比</h3>
                        <div class="echarts_empty hide">
                            <div class="img"></div>
                        </div>
                        <div class="w-100 h-430" id="negativeSourceMap"></div>
                    </div>
                    <div class="box-card w-50">
                        <h3 class="mod-title">微博主要媒体来源</h3>
                        <div class="jzxx hide">
                            <ul class="nav nav-pills" role="tablist1">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">网媒</a>
                                </li>
                                <li class="nav-item active">
                                    <a class="nav-link" data-toggle="pill">微博</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">微信</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">论坛</a>
                                </li>
                                <li class="Switch">
                                    <input type="checkbox" name="Storage" id="sensitive1" />
                                    敏感
                                    <label for="sensitive1"><em></em></label>
                                </li>
                            </ul>
                        </div>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a>sunyuemxb</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">5条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a >我当然不好意思在你面前跳</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.28%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">1.28%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a >正在努力中的妹纸</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.28%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">1.28%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a>DGyjn茜茜</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a>Everytime紫爱</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a >哇亲爱的L</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >平平淡淡Anna</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a >槑槑不是四个呆</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">9</span>
                                    <a >独家记忆78015</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                                <li>
                                    <span class="num">10</span>
                                    <a>秋风6245922800</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 0.96%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">3条</span>
                                    <span class="redu">0.96%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="w-100 sensitive_cont">
                    <div class="box-card w-50 h-450">
                        <h3 class="mod-title">评论主要媒体来源</h3>
                        <div class="jzxx hide">
                            <ul class="nav nav-pills" role="tablist1">
                                <li class="nav-item active">
                                    <a class="nav-link" data-toggle="pill">网媒</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">微博</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">微信</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">论坛</a>
                                </li>
                                <li class="Switch">
                                    <input type="checkbox" name="Storage" id="sensitive1" />
                                    敏感
                                    <label for="sensitive1"><em></em></label>
                                </li>
                            </ul>
                        </div>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a>腾讯网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 33.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">42条</span>
                                    <span class="redu">33.6%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a >新浪网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 30.4%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">38条</span>
                                    <span class="redu">30.4%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a >天天快报</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 12%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">15条</span>
                                    <span class="redu">12%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a >趣头条客户端</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 9.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">12条</span>
                                    <span class="redu">9.6%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a >今日头条</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 8%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">10条</span>
                                    <span class="redu">8%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a >企鹅号</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 3.2%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">3.2%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >微头条</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a >搜狐新闻客户端</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.6%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">1.6%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="box-card w-50 h-450">
                        <h3 class="mod-title">网媒主要媒体来源</h3>
                        <div class="jzxx hide">
                            <ul class="nav nav-pills" role="tablist1">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">网媒</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">微博</a>
                                </li>
                                <li class="nav-item active">
                                    <a class="nav-link" data-toggle="pill">微信</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="pill">论坛</a>
                                </li>
                                <li class="Switch">
                                    <input type="checkbox" name="Storage" id="sensitive1" />
                                    敏感
                                    <label for="sensitive1"><em></em></label>
                                </li>
                            </ul>
                        </div>
                        <div class="echarts_empty" style="display: none;">
                            <div class="img"></div>
                        </div>
                        <div class="tab-content list">
                            <ul>
                                <li>
                                    <h5><span class="num_tit">序号</span><span>媒体</span><span class="redu">条数</span><span class="redu">占比</span></h5>
                                </li>
                                <li>
                                    <span class="num bg-555">1</span>
                                    <a >救援抢险网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 33.82%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">23条</span>
                                    <span class="redu">33.82%</span>
                                </li>
                                <li>
                                    <span class="num bg-689">2</span>
                                    <a >冲孔机网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 25%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">17条</span>
                                    <span class="redu">25%</span>
                                </li>
                                <li>
                                    <span class="num bg-8ea">3</span>
                                    <a >上海毅兮地坪工程有限公司</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 14.7%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">10条</span>
                                    <span class="redu">14.71%</span>
                                </li>
                                <li>
                                    <span class="num">4</span>
                                    <a >腾讯网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 5.88%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">4条</span>
                                    <span class="redu">5.88%</span>
                                </li>
                                <li>
                                    <span class="num">5</span>
                                    <a >澳门豆捞</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 2.94%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">2条</span>
                                    <span class="redu">2.94%</span>
                                </li>
                                <li>
                                    <span class="num">6</span>
                                    <a >上游新闻</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">7</span>
                                    <a >为梦而飞</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">8</span>
                                    <a >主机开设成功</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">9</span>
                                    <a >南京南房建设监理咨询有限公司</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                                <li>
                                    <span class="num">10</span>
                                    <a >南京生活网</a>
                                    <div class="progressBar">
                                        <dl class="barbox">
                                            <dd class="barline">
                                                <div class="charts" style="width: 1.47%;"></div>
                                            </dd>
                                        </dl>
                                    </div>
                                    <span class="redu">1条</span>
                                    <span class="redu">1.47%</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { globalGetEcharts,globalMediaColor } from "@/utils/helpers";
    export default {
        name: 'brief-example-advanced',
        data() {
            return {
                voicetrendData: {
                  "keys":[
                    "2019-12-22 11:00:00",
                    "2019-12-22 12:00:00",
                    "2019-12-22 13:00:00",
                    "2019-12-22 14:00:00",
                    "2019-12-22 15:00:00",
                    "2019-12-22 16:00:00",
                    "2019-12-22 17:00:00",
                    "2019-12-22 18:00:00",
                    "2019-12-22 19:00:00",
                    "2019-12-22 20:00:00",
                    "2019-12-22 21:00:00",
                    "2019-12-22 22:00:00",
                    "2019-12-22 23:00:00",
                    "2019-12-23 00:00:00",
                    "2019-12-23 01:00:00",
                    "2019-12-23 02:00:00",
                    "2019-12-23 03:00:00",
                    "2019-12-23 04:00:00",
                    "2019-12-23 05:00:00",
                    "2019-12-23 06:00:00",
                    "2019-12-23 07:00:00",
                    "2019-12-23 08:00:00",
                    "2019-12-23 09:00:00",
                    "2019-12-23 10:00:00",
                    "2019-12-23 11:00:00"
                  ],
                  "data":[
                    {
                      "name": "微博",
                      "type": "line",
                      "data": [9,0,20,20,14,14,250,203,64,50,37,26,10,16,4,1,0,0,0,0,0,2,0,4,0]
                    },
                    {
                      "name": "评论",
                      "type": "line",
                      "data": [5,4,36,4,14,8,21,20,10,20,12,4,4,6,0,0,0,0,0,2,0,4,0,0,0]
                    },
                    {
                      "name": "网媒",
                      "type": "line",
                      "data": [5,8,7,6,8,5,7,12,7,4,3,5,5,10,8,11,3,9,2,5,5,5,4,4,0]
                    },
                    {
                      "name": "自媒体号",
                      "type": "line",
                      "data": [9,12,2,11,2,16,2,12,3,6,5,3,6,12,2,0,1,0,1,1,3,4,5,0,0]
                    },
                    {
                      "name": "客户端",
                      "type": "line",
                      "data": [5,9,4,3,0,0,1,4,0,3,2,0,3,5,3,6,0,0,0,0,0,0,0,0,0]
                    },
                    {
                      "name": "论坛",
                      "type": "line",
                      "data": [1,0,0,1,0,0,2,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                    },
                    {
                      "name": "视频",
                      "type": "line",
                      "data": [0,0,0,0,0,0,0,0,0,0,0,0,0,0,4,0,0,0,0,0,0,0,0,0,0]
                    },
                    {
                      "name": "微信",
                      "type": "line",
                      "data": [0,0,0,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0,0,0]
                    },
                    {
                      "name": "全部",
                      "type": "line",
                      "data": [34,33,69,45,38,43,283,252,84,83,59,38,28,50,21,18,4,9,3,8,8,15,9,8,0]
                    }
                  ],
                  "medias": ["微博", "评论", "网媒", "自媒体号", "客户端", "论坛", "视频", "微信"]
                },
                emotiontrendData: {
                  "keys":[
                    "2019-12-22 11:00:00",
                    "2019-12-22 12:00:00",
                    "2019-12-22 13:00:00",
                    "2019-12-22 14:00:00",
                    "2019-12-22 15:00:00",
                    "2019-12-22 16:00:00",
                    "2019-12-22 17:00:00",
                    "2019-12-22 18:00:00",
                    "2019-12-22 19:00:00",
                    "2019-12-22 20:00:00",
                    "2019-12-22 21:00:00",
                    "2019-12-22 22:00:00",
                    "2019-12-22 23:00:00",
                    "2019-12-23 00:00:00",
                    "2019-12-23 01:00:00",
                    "2019-12-23 02:00:00",
                    "2019-12-23 03:00:00",
                    "2019-12-23 04:00:00",
                    "2019-12-23 05:00:00",
                    "2019-12-23 06:00:00",
                    "2019-12-23 07:00:00",
                    "2019-12-23 08:00:00",
                    "2019-12-23 09:00:00",
                    "2019-12-23 10:00:00",
                    "2019-12-23 11:00:00"
                  ],
                  "data": [
                    {
                      "name": "敏感",
                      "type": "line",
                      "data": [21,14,48,26,22,20,100,144,31,28,26,25,20,29,11,16,2,5,0,1,3,4,5,3,0]
                    },
                    {
                      "name": "非敏感",
                      "type": "line",
                      "data": [13,19,21,19,16,23,183,108,53,55,33,13,8,21,10,2,2,4,3,7,5,11,4,5,0]
                    }
                  ],
                  "legends":["敏感","非敏感"]
                },
                sensitiveData: {"negative_count":604,"negative_percent":48.63,"positive_count":638,"positive_percent":51.37},
                mediaMapData: [
                  {
                    "name": "微博",
                    "count": 312,
                    "percent": 51.66
                  },
                  {
                    "name": "评论",
                    "count": 125,
                    "percent": 20.7
                  },
                  {
                    "name": "网媒",
                    "count": 68,
                    "percent": 11.26
                  },
                  {
                    "name": "自媒体号",
                    "count": 60,
                    "percent": 9.93
                  },
                  {
                    "name": "客户端",
                    "count": 34,
                    "percent": 5.63
                  },
                  {
                    "name": "论坛",
                    "count": 4,
                    "percent": 0.66
                  },
                  {
                    "name": "微信",
                    "count": 1,
                    "percent": 0.17
                  }
                ],
                negativeMapData: [
                  {
                    "name": "微博",
                    "count": 312,
                    "percent": 51.66
                  },
                  {
                    "name": "评论",
                    "count": 125,
                    "percent": 20.7
                  },
                  {
                    "name": "网媒",
                    "count": 68,
                    "percent": 11.26
                  },
                  {
                    "name": "自媒体号",
                    "count": 60,
                    "percent": 9.93
                  },
                  {
                    "name": "客户端",
                    "count": 34,
                    "percent": 5.63
                  },
                  {
                    "name": "论坛",
                    "count": 4,
                    "percent": 0.66
                  },
                  {
                    "name": "微信",
                    "count": 1,
                    "percent": 0.17
                  }
                ]
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.voicetrend();
                this.emotiontrend();
                this.sensitive();
                this.mediaMap();
                this.negativeMap();
            })
        },
        methods: {
            voicetrend() {
                var element = window.document.getElementById('voicetrend')
                if (!element) {
                    return false
                }
                var voicetrendData = this.voicetrendData;
                var echarts = globalGetEcharts();
                var option = {
                    animation: false,
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        top:"10px",
                        data: voicetrendData.medias,
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle"
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100
                        }
                    ],
                    grid: {
                        left: '40px',
                        bottom: '0',
                        right: '60px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: voicetrendData.keys
                    },
                    yAxis: {
                        type: 'value'
                    },
                    color: ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e', '#7bbdff', '#2ecd42', '#7a5f08', '#95bedd', '#8e8e8e'],
                    series: voicetrendData.data
                }
                var myChart = echarts.init(element);
                myChart.setOption(option);
            },
            emotiontrend() {
                var element = window.document.getElementById('emotiontrend')
                if (!element) {
                    return false
                }
                var emotiontrendData = this.emotiontrendData;
                var echarts = globalGetEcharts();
                emotiontrendData.data.map(function (item) {
                    if (item.name === '敏感') {
                        emotiontrendData.negative = item.data
                    } else if (item.name === '非敏感') {
                        emotiontrendData.positive = item.data
                    }
                })
                var option = {
                    animation: false,
                    tooltip : {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        }
                    },
                    legend: {
                        data:['敏感','非敏感'],
                        top:"10px",
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle"
                    },
                    grid: {
                        top: '60px',
                        left: '20px',
                        bottom:"0",
                        right:"40px",
                        containLabel: true
                    },
                    xAxis : [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: emotiontrendData.keys
                        }
                    ],
                    yAxis : [
                        {
                            type : 'value'
                        }
                    ],
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100
                        }
                    ],
                    color: ['#FC5D73','#555DFE'],
                    series : [
                        {
                            name:'敏感',
                            type:'line',
                            data: emotiontrendData.negative,
                            smooth: true,
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#FC5D73'
                                    }, {
                                        offset: 1,
                                        color: '#fff'
                                    }])
                                }
                            },
                        },
                        {
                            name:'非敏感',
                            type:'line',
                            data: emotiontrendData.positive,
                            smooth: true,
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                        offset: 0,
                                        color: '#555DFE'
                                    }, {
                                        offset: 1,
                                        color: '#fff'
                                    }])
                                }
                            },
                        }
                    ]
                }
                var myChart = echarts.init(element);
                myChart.setOption(option);
            },
            sensitive() {
                var element = window.document.getElementById('sensitive')
                if (!element) {
                    return false
                }
                var echarts = globalGetEcharts();
                var sensitiveData = this.sensitiveData;
                var option = {
                    animation: false,
                    tooltip: {
                        trigger: 'item',
                        formatter: "{a} <br/>{b}: ({d}%)"
                    },
                    legend: {
                        // orient: 'vertical',
                        // x: 'left',
                        data:['敏感','非敏感'],
                        top:"10px",
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:20,
                        icon:"circle",
                        formatter: function (name) {
                            return name == "敏感" ? "敏感 "+sensitiveData.negative_count : "非敏感 "+sensitiveData.positive_count
                        }
                    },
                    color: ['#FC5D73','#555DFE'],
                    series: [
                        {
                            name:'情感分布',
                            type:'pie',
                            center:['50%', '55%'],
                            radius: ['45%', '60%'],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [{name: "敏感", value: sensitiveData.negative_count}, {name: "非敏感", value: sensitiveData.positive_count}]
                        }
                    ]
                }
                var myChart = echarts.init(element);
                myChart.setOption(option);
            },
            mediaMap() {
                var element = window.document.getElementById('mediaSourceMap');
                if (!element) return false
                var mediaMapData = this.mediaMapData;
                var echarts = globalGetEcharts();
                var data = mediaMapData
                var kMaps = {}
                var total = 0
                var keys = []
                data.map(function (item) {
                    total += item.count
                })
                data = data.map(function (item) {
                    var rate = (item.count / total) * 100
                    rate = rate.toFixed(2).toString()
                    kMaps[item.name] = rate + "% "
                    keys.push(item.name)
                    item.itemStyle = {color: globalMediaColor(item.name)}
                    item.value = item.count
                    return item
                })
                var option = {
                    animation: false,
                    tooltip : {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    legend: {
                        orient: 'vertical',
                        left: '20px',
                        bottom: "0",
                        data: keys,
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:5,
                        icon:"circle",
                        formatter: function (name) {
                            return name + " " + kMaps[name]
                        }
                    },
                    series : [
                        {
                            name: '来源分布',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '40%'],
                            data: data,
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal : {
                                    label: {
                                        show: false
                                    },
                                    labelLine: {
                                        show: false
                                    }
                                }
                            }
                        }
                    ]
                };
                var myChart = echarts.init(element);
                myChart.setOption(option);
            },
            negativeMap() {
                var element = window.document.getElementById('negativeSourceMap');
                if (!element) return false
                var negativeMapData = this.negativeMapData;
                var echarts = globalGetEcharts();
                var data = negativeMapData
                var kMaps = {}
                var total = 0
                var keys = []
                data.map(function (item) {
                    total += item.count
                })
                data = data.map(function (item) {
                    var rate = (item.count / total) * 100
                    rate = rate.toFixed(2).toString()
                    kMaps[item.name] = rate + "% "
                    keys.push(item.name)
                    item.itemStyle = {color: globalMediaColor(item.name)}
                    item.value = item.count
                    return item
                })
                var option = {
                    animation: false,
                    tooltip : {
                        trigger: 'item',
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    legend: {
                        orient: 'vertical',
                        left: '10px',
                        itemWidth:10,
                        itemHeight:10,
                        itemGap:5,
                        bottom: "0",
                        data: keys,
                        icon:"circle",
                        formatter: function (name) {
                            return name + " " + kMaps[name]
                        }
                    },
                    series : [
                        {
                            name: '来源分布',
                            type: 'pie',
                            radius: ['45%', '60%'],
                            center: ['50%', '50%'],
                            data: data,
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                },
                                normal : {
                                    label: {
                                        show: false
                                    },
                                    labelLine: {
                                        show: false
                                    }
                                }
                            }
                        }
                    ]
                };
                var myChart = echarts.init(element);
                myChart.setOption(option);
            }
        }
    }
</script>
<style scoped>
*{margin:0;padding: 0;}
table{border-collapse: collapse;}
a{text-decoration: none;cursor: pointer;color: #000;}
li{list-style: none;}
.mar-t-20{margin-top: 20px;}
.mar-t-30{margin-top:30px;}
.t-c{text-align: center;}
#app{background:#E9EEF3;}
.mar-t-20{margin-top: 20px;}
.w-100{width:100%;overflow: hidden;}
.h-430{height: 430px;}
.w-163{width: 163px;}
.h-300{height: 300px;}
.gjrb{margin:0 auto;background: #fff;padding-bottom:30px;width:1380px;}
.gjrb .banner{text-align: center;line-height: 60px;height: 200px;background-image:url(../../../assets/images/common/banner_bg.png); background-repeat:no-repeat; background-size:100% 100%;-moz-background-size:100% 100%;color: #fff;margin-bottom: 30px;}
.gjrb .banner h1{font-size: 30px;padding-top:30px;line-height:80px;}
.gjrb .banner span{padding: 0 30px;font-size: 22px;color:rgba(255,255,255,.79);}
.gjrb .cont{padding: 0 50px;}
.gjrb h2{font-size: 24px;line-height:60px;margin-top: 20px;}
.gjrb p,.gjrb .h3{font-size: 20px;line-height: 40px;color:rgba(0,0,0,.8);}
.box-card{box-shadow: 0px 3px 15px 0px rgba(194, 194, 194, 0.4);border-radius: 6px;padding: 20px 0;}
/**/
.xxjd_table1,.xxjd_table2{width: 100%;margin-top: 20px;}
.xxjd_table1,.xxjd_table1 tr,.xxjd_table1 th,.xxjd_table1 td,.xxjd_table2,.xxjd_table2 tr,.xxjd_table2 th,.xxjd_table2 td{border:2px solid #A4A4A4;line-height:50px;}
.xxjd_table1 th,.xxjd_table2 th{font-size: 18px;}
.xxjd_table1 td,.xxjd_table2 td{font-size: 14px;text-align: center;}
.xxjd_table1 td,.xxjd_table1 th,.xxjd_table2 td,.xxjd_table2 th{padding: 0 10px;}
.xxjd_table1 .title,.xxjd_table1 .source{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;display: block;}
.xxjd_table1 .title{text-align: left;width: 520px;}
.xxjd_table1 .source{width:118px;}
.xxjd_table1 .text-mg,.xxjd_table2 th .mg{color: #f00;}
.xxjd_table1 .text-fmg{color:#555DFE;}
.xxjd_table2,.xxjd_table2 tr,.xxjd_table2 th,.xxjd_table2 td{border-color:#fff;border-bottom:none;border-top: none;}
.xxjd_table2 tr:nth-child(odd){background:rgba(201,224,253,.5);}
.xxjd_table2 tr:nth-child(even){background:rgba(242,242,242,.3);}
.xxjd_table2 td{text-align: center;}
.xxjd_table2 th{color: #fff;background:rgba(0,104,183,.8);}
.xxjd_table2 tr:nth-child(2),.xxjd_table2 tr:nth-child(3),.xxjd_table2 tr:nth-child(4),.xxjd_table2 tr:nth-child(2) .source,.xxjd_table2 tr:nth-child(3) .source,.xxjd_table2 tr:nth-child(4) .source{color: #555DFE;}
.xxsmzs{width:66%;margin:30px auto;}
/*图标空*/
.echarts_empty{width:100%;height:438px;display: flex;justify-content: center;align-items: center; }
.echarts_empty .img{width: 94px;height: 120px;overflow: hidden;background: url(/static/img/echarts_empty.png) 0 0 no-repeat;}
.w-50{width:49%;margin: 30px 0.5%;float: left;display: inline-block;}
/*敏感信息走势*/
.xxjd_table2.mgxx{width:49%;float: right;margin-top: 29px}
.xxjd_table2.mgxx,.xxjd_table2.mgxx tr,.xxjd_table2.mgxx th,.xxjd_table2.mgxx td{line-height:45px;}
/*按钮组居中效果*/
.jzxx,.jzxx2{width:100%;text-align: center;clear: both;margin-top:20px;font-size: 12px;}
.jzxx .nav-pills,.jzxx2 dl{float: none;text-align: center;width:auto;padding: 0 20px;}
.jzxx .nav-pills > li,.jzxx2 dd{float: none;display: inline-block;/*vertical-align: top;*/}
.jzxx2 dd{width: 33%;}
.nav-pills li a{background:#e9e9e9;color:rgba(0,0,0,.47);padding: 6px 15px;border-radius:15px;}
.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus{color:#fff;background:#2a67b3;}
/*开关*/
.jzxx .Switch{width: 90px;}
.Switch input {display: none}
.Switch label {width:46px;background: #CCC;height:21px;border-radius: 14px;float: right;box-shadow: 0 1px 2px rgba(0,0,0,.1) inset;}
.Switch label em {width:20px;height:20px;float: left;margin:0 1px;border-radius: 50%;box-shadow: 2px 3px 8px rgba(0,0,0,.1);background: #FFF;transition: 0.1s;}
.Switch input:checked + label,.sensitive_cont input + label{background: #f00;}
.Switch input:checked + label em,.sensitive_cont input + label em{margin:0 1px 1px 26px;}
.Switch input:disabled + label {opacity: 0.5}
/*列表*/
.list.tab-content{padding:34px 20px 0;}
.list li{line-height: 36px;font-size: 14px;overflow: hidden;}
.list h5 span{float: left;font-size: 14px;}
.list h5 .num_tit{width:50px;text-align: left;}
.list .num{float: left;width:18px;height:18px;background:#d82008;margin:10px 32px 0 0;display: inline;line-height: 16px;color:#fff;text-align: center;font-size: 12px;border-radius:50%;background: #AFC1DB;}
.list .bg-555 {background: #555DFE;}
.list .bg-689 {background:#689AFF;}
.list .bg-8ea {background:#8EA0FF;}
.list.tab-content a{float:left;width:15%;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;}
.list .redu{width:60px;text-align: center;float:right;}
.progressBar{float: left;width:50%;margin-top:10px;}
.progressBar .barline{float: left;width: 100%;background: rgb(223, 223, 223);height:14px;overflow: hidden;display: inline;position: relative;border-radius: 8px;}
.progressBar .charts{width: 70%;background-image: linear-gradient(90deg,#555DFE 0%,#5660d2 100%),linear-gradient(#ffffff,#ffffff);background-blend-mode: normal,normal;height:14px;border-radius: 8px;}
/*敏感内容*/
.sensitive_cont .progressBar .charts{background-image: linear-gradient(90deg, #dd897a 0%, #d33e5e 44%, #e11f37 83%, #ee000f 100%), linear-gradient(#96ddfe, #96ddfe);}
.hide{display: none;}
.xxjd_table2 .bd-l-20{border-left-width:20px;}
.mod-title{padding-left: 15px;margin-left: 20px;}
</style>
